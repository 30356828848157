/* homePage.css */
/* Converting d-flex flex-column min-vh-100 */
.body-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Converting .parallax and overlay styles */
.parallax {
  position: relative;
  overflow: hidden;
  background-color: white;
  height: 100vh;
}

.parallax img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

/* Converting .container */
.container-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Converting overlay-text display-3 text-center py-5 */
.overlay-text {
  color: #edf2f7;
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  font-weight: 600;
  text-align: center;
  padding: 3rem 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 5;
  text-shadow: -2px -2px 0 #0c0c0cdb, 2px -2px 0 #0c0c0cdb, -2px 2px 0 #0c0c0cdb,
    2px 2px 0 #0c0c0cdb;
  backdrop-filter: blur(1px);
  transition: transform 0.3s ease-out;
}

/* Converting .container p-3 */
.section-padding {
  padding: clamp(1rem, 3vw, 2rem);
}

/* Converting .row gx-4 */
.content-grid {
  display: grid;
  grid-template-columns: 66.666667% 33.333333%;
  gap: clamp(1rem, 2vw, 1.5rem);
}

/* Converting .col-lg-8 */
.content-column-large {
  width: 100%;
}

/* Converting .col-lg-4 */
.content-column-small {
  width: 100%;
}

/* Converting .p-4 */
.content-box {
  padding: clamp(1rem, 2vw, 1.5rem);
}

/* Converting .my-2 text-center */
.qasa-description {
  margin: 0.5rem 0;
  text-align: center;
  font-size: clamp(1.2rem, 3vw, 1.7rem);
  color: #5f6462;
}

/* Converting .my-3 text-center */
.download-text {
  margin: 1rem 0;
  text-align: center;
  font-size: clamp(1.2rem, 3vw, 1.7rem);
  color: #090a0d;
  font-weight: 600;
}

/* Converting download links styles */
.download-wrapper {
  display: flex;
  justify-content: center;
  gap: clamp(0.5rem, 2vw, 1rem);
  flex-wrap: wrap;
}

.download-wrapper img {
  height: clamp(60px, 10vw, 83px);
  max-width: 100%;
  transition: transform 0.2s ease;
}

.download-wrapper img:hover {
  transform: scale(1.05);
}

/* Converting .img-fluid */
.fluid-image {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.inline-image {
  display: inline-block;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 992px) {
  .content-grid {
    grid-template-columns: 100%;
    gap: 2rem;
  }

  .overlay-text {
    white-space: normal;
    width: 90%;
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .download-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .content-box {
    padding: 1rem;
  }

  .parallax {
    height: 70vh;
  }

  .parallax img {
    height: 70vh;
  }
}

@media (max-width: 480px) {
  .qasa-description,
  .download-text {
    padding: 0 1rem;
  }

  .overlay-text {
    width: 95%;
    font-size: clamp(2rem, 4vw, 2.5rem);
  }
}
