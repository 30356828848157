body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   border-radius: 100rem;
}
}
#sharethis-inline-buttons{
  z-index: 10 !important;
}

code {
  font-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter.ttf);
}

.Toastify svg{
  color: white !important;
}


/* div.atcb-initialized div.atcb-list-wrapper.atcb-dropdown div#atcb-reference {
  display: none !important;
}

#atcb-reference {
  display: none !important;
} */

#st-cmp-v2{
  display: none !important; 
}

.fb-button{
  width: 100%;
}

