/* privacyPolicy.css */
.main-container {
  width: 100%;
  max-width: 1200px;
  margin: 0.25rem auto;
  padding: 0 15px;
  overflow-x: hidden;
}

.main-title {
  font-size: 3rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 3rem;
  text-align: left;
  margin-top: 2rem;
  margin-left: 10rem;
  padding-top: 3rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.intro-text {
  color: rgb(158, 163, 174);
  font-size: 1.25rem;
  font-weight: 0;
  line-height: 1.5;
  margin-bottom: 4rem;
  width: 66.666667%;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
  
.section-title {
  font-size: 2.25rem;
  color: #666666;
  margin-bottom: 1rem;
  font-weight: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.section-text {
  color: rgb(158, 163, 174);
  font-size: 1.25rem;
  font-weight: 0;
  line-height: 1.75;
  margin-bottom: 2rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.section-text a {
  color: #009966;
  text-decoration: none;
  transition: all 0.2s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.section-text a:hover {
  text-decoration: underline;
}

.deletion-steps {
  margin: 1rem 0;
  padding-left: 2rem;
  list-style-type: decimal;
  max-width: 100%;
}

.deletion-steps li {
  color: rgb(158, 163, 174);
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75;
  margin-bottom: 0.5rem;
  padding-right: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Tablet screens */
@media (max-width: 1023px) {
  .main-container {
      padding: 0 2rem;
  }

  .main-title {
      margin-left: 5rem;
      font-size: 2.75rem;
      padding-right: 2rem;
  }

  .intro-text {
      width: 80%;
      padding-right: 2rem;
  }

  .section-text {
      padding-right: 2rem;
  }
}

/* Medium screens */
@media (max-width: 768px) {
  .main-container {
      padding: 0 1.5rem;
  }

  .main-title {
      font-size: 2.5rem;
      margin-left: 2rem;
      margin-bottom: 2rem;
      padding-top: 2rem;
      padding-right: 1.5rem;
  }

  .intro-text {
      width: 100%;
      font-size: 1.125rem;
      margin-bottom: 3rem;
      padding-right: 1.5rem;
  }

  .section-title {
      font-size: 2rem;
      padding-right: 1.5rem;
  }

  .section-text {
      font-size: 1rem;
      padding-right: 1.5rem;
  }

  .deletion-steps {
      padding-right: 1.5rem;
  }

  .deletion-steps li {
      font-size: 1rem;
  }
}

/* Mobile screens */
@media (max-width: 480px) {
  .main-container {
      padding: 0 1rem;
  }

  .main-title {
      font-size: 2rem;
      margin-left: 1rem;
      margin-bottom: 1.5rem;
      padding-top: 1.5rem;
      padding-right: 1rem;
  }

  .section-title {
      font-size: 1.75rem;
      padding-right: 1rem;
  }

  .intro-text {
      margin-bottom: 2rem;
      padding-right: 1rem;
  }

  .section-text {
      padding-right: 1rem;
  }

  .section-text, 
  .intro-text,
  .deletion-steps li {
      font-size: 0.938rem;
  }

  .deletion-steps {
      padding-left: 1.5rem;
      padding-right: 1rem;
  }
}

/* Extra small screens */
@media (max-width: 360px) {
  .main-container {
      padding: 0 0.75rem;
  }

  .main-title {
      margin-left: 0.5rem;
      padding-right: 0.75rem;
  }

  .section-title,
  .section-text,
  .intro-text {
      padding-right: 0.75rem;
  }

  .deletion-steps {
      padding-right: 0.75rem;
  }
}

/* Print styles */
@media print {
  .main-container {
      max-width: none;
      padding: 0;
  }

  .main-title {
      margin: 1rem 0;
      padding-top: 0;
  }

  .section-text,
  .intro-text,
  .deletion-steps li {
      color: #000;
  }

  .section-text a {
      text-decoration: underline;
      color: #000;
  }
}