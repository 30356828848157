/* faq.css */
.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
    width: 100%;
}

.faq-container {
    width: 100%;
    max-width: 1200px;
    margin: 0.25rem auto;
    padding: 0 15px;
    flex: 1 0 auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

/* Header Section */
.header-section {
    padding: 3rem;
    text-align: center;
    background-color: rgb(248, 249, 250);
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.faq-icon {
    margin: 0 0.5rem;
}

.main-title {
    color: #212529;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 1rem 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.subtitle {
    color: #6c757d;
    font-size: 1.25rem;
    max-width: 66.666667%;
    margin: 0 auto;
    font-weight: 300;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.smiley-icon {
    height: 20px;
    width: 20px;
    vertical-align: middle;
    margin-left: 4px;
}

/* Accordion Styles */
.accordion {
    width: 100%;
    margin-bottom: 2rem;
    overflow: hidden;
    box-sizing: border-box;
}

.accordion-item {
    border: none;
    margin-bottom: 0;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.accordion-item:last-child {
    border-bottom: none;
}

.accordion-item:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
}

.accordion-header {
    margin: 0;
    font-weight: 900;
    width: 100%;
}

.accordion-button {
    width: 100%;
    padding: 1.5rem;
    border: 3px solid transparent;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
}

.accordion-button.active,
.accordion-button.focus {
    background-color: rgb(239, 246, 255);
    border: 3px solid rgb(59, 130, 246);
}

.accordion-button h4 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
    color: #212529;
    padding-right: 2.5rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    flex: 1;
}

.accordion-button::after {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
    flex-shrink: 0;
    position: absolute;
    right: 1.5rem;
}

.accordion-button.active::after {
    transform: rotate(-180deg);
}

.accordion-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease-in-out;
    background-color: transparent;
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.accordion-content.show {
    height: auto;
    opacity: 1;
    visibility: visible;
    padding: 1.5rem;
    background-color: transparent;
    width: 100%;
}

.accordion-body {
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    color: #8690a5;
    background-color: transparent;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.accordion-body p {
    margin-bottom: 1rem;
    color: #8690a5;
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
}

.accordion-body strong {
    font-weight: 600;
    color: #212529;
}

.signup-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    align-items: start;
    width: 100%;
    box-sizing: border-box;
}

.signup-text {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
}

.content-image {
    width: 100%;
    height: auto;
    border-radius: 0.375rem;
    max-width: 100%;
    object-fit: contain;
}

.contact-images {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
}

.contact-image {
    max-width: 300px;
    height: auto;
    margin: 0.5rem;
    border-radius: 0.375rem;
    object-fit: contain;
}

.accordion-body a {
    color: #009966;
    text-decoration: none;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.accordion-body a:hover {
    text-decoration: underline;
}

.accordion-body ol,
.accordion-body ul {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    color: inherit;
    width: 100%;
    box-sizing: border-box;
}

.accordion-body li {
    margin-bottom: 0.5rem;
    color: inherit;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    padding-right: 1rem;
}

/* Helper classes */
.text-light { font-weight: 300; }
.text-regular { font-weight: 400; }
.text-medium { font-weight: 500; }
.text-semibold { font-weight: 600; }
.text-bold { font-weight: 700; }

/* Large screens */
@media (min-width: 1200px) {
    .faq-container {
        padding: 0 2rem;
    }
}

/* Desktop and Tablet */
@media (max-width: 992px) {
    .header-section {
        margin-top: 2rem;
    }
    
    .subtitle {
        max-width: 100%;
        padding: 0 1rem;
    }

    .signup-content {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .contact-images {
        flex-direction: column;
        align-items: center;
    }

    .contact-image {
        max-width: 100%;
        margin: 0.5rem 0;
    }
}

/* Tablet */
@media (max-width: 768px) {
    .header-section {
        padding: 2rem 1rem;
        margin-top: 1.5rem;
    }

    .main-title {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1.125rem;
    }

    .accordion-button {
        padding: 1.25rem;
    }

    .accordion-button h4 {
        font-size: 1.25rem;
    }

    .accordion-content.show {
        padding: 1.25rem;
    }

    .accordion-body {
        font-size: 1.125rem;
    }

    .signup-text {
        font-size: 1.125rem;
    }
}

/* Mobile */
@media (max-width: 480px) {
    .faq-container {
        padding: 0 1rem;
    }

    .header-section {
        padding: 1.5rem 1rem;
        margin-top: 1rem;
    }

    .main-title {
        font-size: 1.75rem;
    }

    .subtitle {
        font-size: 1rem;
    }

    .accordion-button {
        padding: 1rem;
    }

    .accordion-button h4 {
        font-size: 1.125rem;
    }

    .accordion-content.show {
        padding: 1rem;
    }

    .accordion-body {
        font-size: 1rem;
        padding: 0.75rem;
    }

    .signup-text {
        font-size: 1rem;
    }

    .contact-images {
        gap: 0.5rem;
    }

    .contact-image {
        margin: 0.25rem;
    }
}

/* Extra small screens */
@media (max-width: 360px) {
    .faq-container {
        padding: 0 0.75rem;
    }

    .header-section {
        padding: 1rem;
    }

    .accordion-button {
        padding: 0.75rem;
    }

    .accordion-content.show {
        padding: 0.75rem;
    }
}

/* Print styles */
@media print {
    .faq-container {
        max-width: none;
        padding: 0;
    }

    .main-title {
        margin: 1rem 0;
        padding-top: 0;
    }

    .accordion-body {
        color: #000;
    }

    .accordion-body a {
        text-decoration: underline;
        color: #000;
    }
}