/* download.css */
.download-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
  }
  
  .download-container {
    padding: 1rem;
    margin: 1.5rem 0;
    background-color: white;
    border-radius: 0.375rem;
  }
  
  .download-content {
    padding: 3rem 1rem;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .download-content h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .download-content p {
    font-size: 1.5rem;
    color: #5F6462;
    margin: 0 auto 2rem;
    max-width: 800px;
    line-height: 1.5;
  }
  
  .download-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.25rem;
  }
  
  .store-button {
    text-decoration: none;
    display: inline-block;
  }
  
  .store-button img {
    height: 83px;
    width: auto;
    border-radius: 13px;
    overflow: hidden;
  }
  
  @media (max-width: 768px) {
    .download-content h1 {
      font-size: 2.5rem;
    }
  
    .download-content p {
      font-size: 1.25rem;
    }
  
    .download-buttons {
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
    .download-content {
      padding: 2rem 1rem;
    }
  
    .download-content h1 {
      font-size: 2rem;
    }
  
    .download-content p {
      font-size: 1.125rem;
    }
  
    .store-button img {
      height: 70px;
    }
  }