.event-details-section {
  width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(146, 193, 223, 0.2);
  border-radius: 20px;
  width: 100%;
  overflow: auto;
}

.flex-item:nth-child(1) {
  flex: 0 0 60%;
}

.flex-item:nth-child(2) {
  flex: 0 0 40%;
}

@media screen and (max-width: 992px) {
  .flex-item:nth-child(1) {
    flex: 0 0 100%;
  }
  .flex-item:nth-child(2) {
    flex: 0 0 100%;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }

  .flex-item {
    width: 100%;
    min-width: 0;  /*Allows flex items to shrink below content size*/
  }

   .events {
    width: 100%;
    box-sizing: border-box;
  }
}

.event-image-flyer {
  margin-bottom: 4%;
}
.event-flyer {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-fit: top;
  border-radius: 20px;
}

.border-highlighter {
  content: "";
  background-color: rgb(0, 159, 0);
  height: 5px;
  width: 70px;
  border-radius: 20px;
}

.events {
  padding: 35px;
}

.event-content-header {
  margin-top: 0px;
}

.event-content-sub-header {
  color: gray;
  font-weight: bold;
  margin: 3% 0%;
}

.event-content-sub-info {
  color: gray;
  font-weight: normal;
}

.event-pricing-content {
  display: flex;
  flex-wrap: wrap;
  color: gray;
  font-weight: bold;
  gap: 20px;
  margin: 3% 0%;
}

.event-input {
  border: none;
  height: 35px;
  border-radius: 10px;
  text-align: center;
  width: 60px;
}

.event-promo-code-input {
  border: none;
  height: 40px;
  border-radius: 20px;
  margin-right: 5px;
  width: 210px;
}

.ticket-control-btn {
  background: white;
  margin-right: 10px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 30%;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.pricing-info {
  width: 220px;
}

.custom_title{
  margin-left: 10px;
  margin-bottom: 10px;
}

::placeholder {
  color: #bbbbbb;
  padding: 0px 10px;
}

.promo-code {
  margin: 6% 0%;
}

.amount-total {
  margin: 6% 0%;
}

.event-submit-btn {
  width: 100%;
  max-width: 280px;
  height: 50px;
  background-color: rgb(0, 159, 103);
  border: none;
  border-radius: 30px;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
}

.event-submit-btn:hover {
  width: 100%;
  height: 50px;
  max-width: 280px;
  background-color: rgba(5, 161, 106, 0.694);
  border: none;
  border-radius: 30px;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
}

.event-submit-btn-disabled {
  width: 100%;
  height: 50px;
  max-width: 280px;
  background-color: rgb(78, 78, 78);
  border: none;
  border-radius: 30px;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
  margin-top: 20px;
}

.checkout-button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .checkout-button-wrapper {
    position: static;
    box-shadow: none;
  }
}

.wallet-details {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 35px;
}

.error-message {
  color: red;
}

.wallet-details-sub {
  margin-top: 0px;
  margin-bottom: 0px;
  color: gray;
  font-weight: normal;
}

.promo-code-button {
  width: 70px;
  height: 40px;
  background-color: rgb(0, 159, 103);
  border: none;
  border-radius: 30px;
  color: white;
  margin-top: 20px;
  margin-left: 15px;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
}

.promo-code-button:hover {
  width: 70px;
  height: 40px;
  background-color: rgba(5, 161, 106, 0.694);
  border: none;
  border-radius: 30px;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
}

.vertical-separator {
  border-left: 2px dotted #ccc;
  margin: 30px 10px 30px 10px;
}

/* ########## checkout css ##### */

.checkout-section {
  margin: 50px 20px;
  height: auto;
}
.checkout-option-1 {
  display: flex;
  gap: 20px;
}

.checkout-option-2 {
  display: flex;
  gap: 20px;
  padding: 5px 0px;
}

.button-image {
  width: 100%;
  height: auto;
  max-width: 48px; /* Adjust as needed */
  max-height: 28px; /* Adjust as needed */
}

.checkout-option-btn-new {
  border: 2px solid #81C6DA;
  border-radius: 10px;
  padding: 15px 20px;
  background: white;
  margin-bottom: 10px;
}

.checkout-option-btn-new:hover {
  border: 2px solid rgb(0, 0, 0);
}

.checkout-option-btn {
  border: 1px solid #81C6DA;
  border-radius: 8px;
  /* width:100px;
    height:40px; */
  padding: 15px 20px;
  background: white;
}

.checkout-option-btn:hover {
  border: 2px solid rgb(0, 120, 211);
}

.checkout-header {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.checkout-form-display {
  margin-top: 10px;
}
.checkout-form-hidden {
  display: none;
}

.checkout-input {
  border: 1px solid lightskyblue;
  border-radius: 20px;
  width: 300px;
  height: 40px;
  margin-right: 10px;
}

.wrap-text {
  display: flex;
  word-break: normal;
  width: 750px;
}

.checkout-submit-btn {
  width: 280px;
  height: 50px;
  background-color: rgb(0, 159, 103);
  border: none;
  border-radius: 30px;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
  margin-top: 20px;
}

.checkout-submit-btn:hover {
  width: 280px;
  height: 50px;
  background-color: rgba(5, 161, 106, 0.694);
  border: none;
  border-radius: 30px;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
}
