.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav-wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.support-container {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}

.help-section {
  padding-top: 2rem;
}

.help-content {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 4rem;
}

.help-info {
  flex: 1;
}

.help-card {
  background-color: #edf2f7;
  padding: 2rem;
  border-radius: 0.5rem;
}

.help-card h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: "Inter", sans-serif;
}

.help-card p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: #5f6462;
}

.help-link {
  background: none;
  border: none;
  color: #009966;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font: inherit;
}

.lottie-player {
  width: 300px;
  height: 300px;
}

.videos-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin: 2.5rem 0;
  padding: 0 1rem;
}

.video-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.video-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-details {
  padding: 1rem;
  background-color: #edf2f7;
  flex-grow: 1;
}

.video-details h5 {
  font-size: 1.25rem;
  margin: 0 0 0.5rem 0;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.video-details p {
  margin: 0;
  color: #5f6462;
  line-height: 1.4;
}

.social-header {
  background-color: #009966;
  color: white;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  margin: 3rem 0;
}

.social-header h3 {
  margin: 0;
  text-transform: capitalize;
  font-size: 1.5rem;
}

.social-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #000;
  transition: transform 0.2s ease;
}

.social-link:hover {
  transform: scale(1.05);
}

.social-link svg {
  width: 3rem;
  height: 3rem;
}

.social-link span {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
}

.footer-wrapper {
  width: 100%;
  margin-top: auto;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .videos-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .help-content {
    flex-direction: column;
  }

  .videos-section {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .social-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .social-link svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  .social-link span {
    font-size: 1.2rem;
  }

  .help-card h1 {
    font-size: 2rem;
  }

  .help-card p {
    font-size: 1.1rem;
  }

  .lottie-player {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 480px) {
  .social-grid {
    grid-template-columns: 1fr;
  }

  .video-details h5 {
    font-size: 1.1rem;
  }

  .video-details p {
    font-size: 0.9rem;
  }

  .social-header h3 {
    font-size: 1.3rem;
  }

  .help-card {
    padding: 1.5rem;
  }

  .help-card h1 {
    font-size: 1.8rem;
  }

  .help-card p {
    font-size: 1rem;
  }

  .lottie-player {
    width: 200px;
    height: 200px;
  }
}
