/* termsOfService.css */
.main-container {
    width: 100%;
    max-width: 1200px;
    margin: 0.25rem auto;
    padding: 0 15px;
}

.main-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    margin-bottom: 3rem;
    text-align: left;
    margin-top: 2rem;
    margin-left: 10rem;
    padding-top: 3rem;
}

.intro-text {
    color: rgb(158, 163, 174);
    font-size: 1.25rem;
    font-weight: 0;
    line-height: 1.5;
    margin-bottom: 4rem;
    width: 66.666667%;
}
  
.section-title {
    font-size: 2.25rem;
    color: #666666;
    margin-bottom: 1rem;
    font-weight: 1;
}

.section-text {
    color: rgb(158, 163, 174);
    font-size: 1.25rem;
    font-weight: 0;
    line-height: 1.75;
    margin-bottom: 2rem;
}

.section-text a {
    color: #009966;
    text-decoration: none;
}

.section-text a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .main-title {
        font-size: 2.5rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .section-text,
    .intro-text {
        font-size: 1rem;
    }
}