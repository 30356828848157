/* navbar.css */
.navbar {
  background-color: #fff;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  height: 50px !important;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
}

.navbar-brand img {
  width: 100px;
  height: auto;
}

/* Navigation links */
.nav-links {
  display: flex;
  padding-left: 0;
  margin: 0;
  list-style: none;
  margin-left: auto;
}

.nav-links li {
  margin-left: 1rem;
}

.nav-links a {
  color: #212529;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 600;
  line-height: 2rem;
}

.nav-links a:hover {
  color: #0CAF6D;
}

.nav-links a.active {
  font-weight: bold;
  color: #000;
}

/* Menu Toggle Button */
.menu-toggle {
  padding: 0.25rem 0.75rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  display: none;
}

.menu-toggle-icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

/* Offcanvas Menu */
.navbar-menu {
  display: flex;
  align-items: center;
}

.menu-header {
  display: none;
}

/* Media Queries - Mobile Styles */
@media (max-width: 992px) {
  .menu-toggle {
    display: block;
  }

  .navbar-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: white;
    transition: right 0.3s ease-in-out;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .navbar-menu.open {
    right: 0;
  }

  .menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: relative;
    width: 100%;
  }

  .menu-header h5 {
    margin: 0;
    padding: 1rem;
    font-size: 1.25rem;
    color: #212529;
    position: absolute;
    left: 1rem;
  }

  .close-button {
    background: transparent;
    border: none;
    padding: 0;
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }

  .close-icon::before,
  .close-icon::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 2px;
    background-color: #212529;
    top: 50%;
    left: 50%;
  }

  .close-icon::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .close-icon::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    margin-top: 3.5rem;
    padding: 1rem;
  }

  .nav-links li {
    margin: 0;
    width: 100%;
    padding: 0.5rem 0;
  }

  .nav-links a {
    padding: 0.5rem 0;
    font-size: 2.5rem;
    color: #6c757d;
    font-weight: 400;
    line-height: 1.4;
  }

  .nav-links a:hover {
    color: #0CAF6D;
  }
}

/* Additional responsive tweaks */
@media (max-width: 576px) {
  .navbar-brand img {
    width: 80px;
  }

  .navbar-menu {
    width: 100%;
  }

  .nav-links a {
    font-size: 2.5rem;
  }
}

/* Fix for body scroll when menu is open */
body.menu-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

/* Additional styles */
.offcanvas-body .nav-link {
  font-weight: normal;
  color: #212529;
  line-height: 2rem;
}

@media (max-width: 767px) {
  .offcanvas-body .nav-link {
    font-weight: normal;
    font-size: 2.5rem;
    color: #212529 !important;
    line-height: 2.8rem;
  }
}

.nav-link:hover {
  color: #0CAF6D !important;
}

a.nav-link:visited {
  color: #212529;
}

/* Fix viewport height issues on mobile */
@media (max-width: 992px) {
  .navbar-menu {
    height: 100vh !important;
    min-height: -webkit-fill-available;
  }
}

/* Ensure menu takes full height on iOS */
@supports (-webkit-touch-callout: none) {
  .navbar-menu {
    height: -webkit-fill-available;
  }
}