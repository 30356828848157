/* footer.css */
.footer {
  padding: 1rem;
  margin-top: auto;
  background-color: #f8f9fa;
  width: 100%;
}

.nav {
  display: flex;
  flex-wrap: wrap;  /* Enable wrapping for smaller screens */
  justify-content: center;
  gap: 0.5rem;  /* Space between wrapped items */
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
}

.nav-item {
  padding: 0;
  margin: 0;
}

.nav-link {
  display: block;
  padding: 0.5rem;
  color: #6c757d;
  text-decoration: none;
  font-weight: 400;
  font-size: 0.875rem;  /* Smaller font size for mobile */
  transition: color 0.2s ease-in-out;
}

.nav-link:visited {
  color: #6c757d;
}

.nav-link:hover {
  color: #009966;
}

.copyright-text {
  text-align: center;
  color: #6c757d;
  font-size: 0.875rem;
  margin: 0;
}

.copyright-text a {
  color: #009966;
  text-decoration: none;
  transition: text-decoration 0.2s ease-in-out;
}

.copyright-text a:hover {
  text-decoration: underline;
}

/* Container to keep content within reasonable bounds */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .nav {
    gap: 0.25rem;  /* Smaller gap on mobile */
  }
  
  .nav-link {
    padding: 0.375rem 0.75rem;  /* Smaller padding on mobile */
  }
}

@media screen and (min-width: 769px) {
  .nav-link {
    font-size: 1rem;  /* Larger font size on desktop */
    padding: 0.5rem 1rem;  /* Larger padding on desktop */
  }
  
  .copyright-text {
    font-size: 1rem;  /* Larger font size on desktop */
  }
  
  .nav {
    gap: 1rem;  /* Larger gap on desktop */
  }
}

/* For very small screens */
@media screen and (max-width: 480px) {
  .footer {
    padding: 0.75rem;  /* Slightly smaller padding */
  }
  
  .nav {
    justify-content: flex-start;  /* Left align items on very small screens */
    padding-bottom: 0.75rem;
  }
  
  .nav-link {
    padding: 0.25rem 0.5rem;  /* Even smaller padding */
  }
}

/* Ensure footer stays at bottom if content is short */
html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}